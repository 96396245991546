// НЕ ИСПОЛЬЗОВАТЬ НА КЛИЕНТЕ
// НОДА НЕ УМЕЕТ В ИМПОРТ МЕТА ЕНВ, КЛИЕНТ НЕ УМЕЕТ В ПРОЦЕСС ЕНВ
export function getUrlToRedirect(
  isAuthorisedUser: boolean,
  originalUrl: string,
  currentLocale: string,
): `/${string}` | '' {
  if (originalUrl.includes('/trackingA/')) {
    if (isAuthorisedUser) {
      return `/${currentLocale}/cabinet/orders/`;
    }

    return `/${currentLocale}/tracking/`;
  }

  if (originalUrl.includes('individuals_old')) {
    return `/${currentLocale}/individuals/`;
  }

  if (originalUrl.includes('/calculate')) {
    return `/${currentLocale}/cabinet/calculate/`;
  }

  if (!isAuthorisedUser) {
    return '';
  }

  if (originalUrl.includes('/tracking')) {
    return `/${currentLocale}/cabinet/orders/`;
  }

  if (originalUrl.includes('/cdekid')) {
    return `/${currentLocale}/cabinet/cdekid/`;
  }

  if (originalUrl.includes('/gifts')) {
    return `/${currentLocale}/cabinet/gifts/`;
  }

  return '';
}
