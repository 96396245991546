import { redirect } from 'vite-plugin-ssr/abort';
import trim from 'lodash/trim';
import type { PageContext } from 'vite-plugin-ssr/types';
import { getUrlToRedirect } from '@/utils/handle-node-redirect';

function buildRegexpForCheckingUrl(shortLangs: string[]) {
  return new RegExp(`^/${shortLangs.join('|')}(?:/|$)`);
}

function addLocaleToUrl(url: string, locale: string): `/${string}/` {
  url = trim(url, '/');

  if (/^[a-z]{2}(?:\/|$)/.test(url)) {
    url = url.length === 2 ? '' : url.slice(3);
  }

  if (url) {
    url = `/${url}`;
  }

  return `/${locale}${url}/`;
}

function parseUrl(url: string) {
  const [, locale, ...rest] = url.split('/');

  const restUrl = rest.join('/');

  return {
    currentLocale: locale,
    urlWithoutLocale: restUrl,
  };
}

export function onBeforeRoute(pageContext: PageContext) {
  if (typeof window !== 'undefined') {
    return;
  }

  const { urlPathname, locale: currentLocale, availableLocales, isAuthorizedUser } = pageContext;

  if (urlPathname.startsWith('/resolver')) {
    return {
      pageContext: {
        ...parseUrl(urlPathname),
      },
    };
  }

  const [defaultLanguage] = availableLocales;

  const startsWithAvailableLocale = buildRegexpForCheckingUrl(availableLocales).test(urlPathname);

  const newLocale = availableLocales.includes(currentLocale || '')
    ? (currentLocale as string)
    : defaultLanguage;

  let baseRedirectUrl = getUrlToRedirect(isAuthorizedUser, urlPathname, newLocale);

  if (baseRedirectUrl) {
    if (pageContext.urlParsed.searchOriginal) {
      baseRedirectUrl += pageContext.urlParsed.searchOriginal;
    }

    throw redirect(baseRedirectUrl as `/${string}`);
  }

  if (!startsWithAvailableLocale) {
    let url = addLocaleToUrl(urlPathname, newLocale);

    if (pageContext.urlParsed.searchOriginal) {
      url += pageContext.urlParsed.searchOriginal;
    }

    throw redirect(url as `/${string}`);
  }

  return {
    pageContext: {
      ...parseUrl(urlPathname),
    },
  };
}
